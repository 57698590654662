import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import CardsBuilder from "../common/cardsBuilder";
import { useTranslation } from "react-i18next";
import get from "lodash/get";

const Services = () => {
    const { t } = useTranslation();

    const textData = [];
    const services = [
        "mobile",
        "frontend",
        "backend",
        "design",
        "quality",
        "administration",
    ];

    for (let i = 0; i < 6; i++) {
        textData.push({
            id: i,
            title: t(`services.${services[i]}.title`),
            description: t(`services.${services[i]}.description`),
        });
    }

    const data = useStaticQuery(graphql`
        query {
            servicesImages: allFile(
                filter: {
                    extension: { regex: "/(svg)/" }
                    absolutePath: { regex: "/index/services/" }
                }
                sort: { fields: name }
            ) {
                edges {
                    node {
                        name
                        publicURL
                    }
                }
            }
        }
    `);

    let imagesData = data.servicesImages.edges.map(item => get(item, "node"));

    return (
        <div className="services" id="services">
            <div className="component-title">{t("services.title")}</div>
            <CardsBuilder
                className={"service"}
                data={textData}
                image={imagesData}
                separator={true}
            />
        </div>
    );
};

export default Services;
