import React from "react";

import success_logo from '../../images/index/upload/success.svg'
import fail_logo from '../../images/index/upload/fail.svg'

const Overlay = () => {
    const handleClick = e => {
        const currentTarget = e.currentTarget;
        currentTarget.style.opacity = "0";
        setTimeout(() => (currentTarget.style.zIndex = "-1"), 1000);
    };
    return (
        <React.Fragment>
            <div className="overlay" id="loader-overlay">
                <div className="loader">
                    <div className="item-1">{null}</div>
                    <div className="item-2">{null}</div>
                    <div className="item-3">{null}</div>
                    <div className="item-4">{null}</div>
                    <div className="item-5">{null}</div>
                </div>
            </div>
            <div className="overlay" id="success-overlay" onClick={handleClick}>
                <div className="success">
                    <div id="close-message">{null}</div>
                    <i className="success-icon">
                        <img src={success_logo} alt="" />
                    </i>
                    <p>Спасибо, ваша заявка отправлена!</p>
                    <p>Мы свяжемся с вами в течение рабочего дня.</p>
                </div>
            </div>
            <div className="overlay" id="fail-overlay" onClick={handleClick}>
                <div className="success">
                    <div id="close-message">{null}</div>
                    <i className="success-icon">
                        <img src={fail_logo} alt="" />
                    </i>
                    <p>Что-то пошло не так...</p>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Overlay;
