import React from "react";
import {useStaticQuery, graphql} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import {useTranslation} from "react-i18next";

const Intro = () => {
    const {t} = useTranslation();

    const data = useStaticQuery(graphql`
        query {
            introImage: file(relativePath: {eq: "index/stages/stage-0.jpg"}) {
                childImageSharp {
                    gatsbyImageData
                }
            }
        }
    `);

    return (
        <div className="intro">
            <div className="intro-title">{t("intro.title")}</div>
            <div className="intro-description">{t("intro.description")}</div>
            <a className="intro-button" href={"#contact"}>
                {t("intro.button")}
            </a>
            <div className="intro-image">
                <GatsbyImage alt="" image={data.introImage.childImageSharp.gatsbyImageData} />
            </div>
            <div className="separator">{null}</div>
        </div>
    );
};

export default Intro;
