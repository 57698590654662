import React from "react";
import {useStaticQuery, graphql} from "gatsby";
import StageBuilder from "./common/stageBuilder";
import {useTranslation} from "react-i18next";

const Projects = () => {
    const {t} = useTranslation();

    const textData = [];

    for (let i = 0; i < 3; i++) {
        textData.push({
            id: i,
            title: t(`projects.${i + 1}.title`),
            description: t(`projects.${i + 1}.description`),
            destination: t(`projects.${i + 1}.destination`),
        });
    }

    const data = useStaticQuery(graphql`
        query {
            indexProject0: file(relativePath: {eq: "index/projects/index-projects-0.png"}) {
                childImageSharp {
                    gatsbyImageData
                }
            }
            indexProject1: file(relativePath: {eq: "index/projects/index-projects-1.png"}) {
                childImageSharp {
                    gatsbyImageData
                }
            }
            indexProject2: file(relativePath: {eq: "index/projects/index-projects-2.png"}) {
                childImageSharp {
                    gatsbyImageData
                }
            }
        }
    `);

    let imagesData = [];

    for (let i = 0; i < 3; i++) {
        imagesData.push(data[`indexProject${i}`].childImageSharp.gatsbyImageData);
    }

    return (
        <div className="stages projects" id="projects">
            <div className="component-title">{t("projects.title")}</div>
            <StageBuilder type={"project"} data={textData} image={imagesData} />
        </div>
    );
};

export default Projects;
