import React from "react";
import Services from "../components/index/services";
import Layout from "../components/layout";
import Intro from "../components/index/intro";
import Technologies from "../components/index/technologies";
import Stages from "../components/index/stages";
import Projects from "../components/index/projects";
import Products from "../components/index/products";
import Contact from "../components/index/contact";
import Overlay from "../components/index/overlay";

const IndexPage = () => (
    <Layout>
        <div className="container">
            <Intro />
            <Services />
        </div>
        <Stages />
        <Projects />
        <Products />
        <div className="container">
            <Technologies />
            <Contact/>
        </div>
        <Overlay/>
    </Layout>
);

export default IndexPage;
