import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import CardsBuilder from "../common/cardsBuilder";
import { useTranslation } from "react-i18next";
import get from "lodash/get";

const Technologies = () => {
    const { t } = useTranslation();

    const textData = [];
    const tech = ["frontend", "backend", "infrastructure"];

    for (let i = 0; i < 3; i++) {
        textData.push({
            id: i,
            title: t(`tech.${tech[i]}.title`),
            description: t(`tech.${tech[i]}.description`),
        });
    }

    const data = useStaticQuery(graphql`
        query {
            techImages: allFile(
                filter: {
                    extension: { regex: "/(svg)/" }
                    absolutePath: { regex: "/index/technologies/" }
                }
                sort: { fields: name }
            ) {
                edges {
                    node {
                        name
                        publicURL
                    }
                }
            }
        }
    `);

    let imagesData = data.techImages.edges.map(item => get(item, "node"));

    return (
        <div className="technologies" id="technologies">
            <div className="component-title">{t("tech.title")}</div>
            <CardsBuilder
                className={'technology'}
                data={textData}
                image={imagesData}
            />
        </div>
    );
};

export default Technologies;
