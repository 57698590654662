import React from "react";
import {Link} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

const Stage = ({type, destination, id, title, description, imag}) => {
    const {t} = useTranslation();

    return (
        <div className="stage">
            {!!type && type === "project" ? (
                <div className={"projects-image-bg"}>{null}</div>
            ) : type === "product" ? (
                <div className={"projects-image-bg"}>{null}</div>
            ) : null}
            <div className={`stage-info ${type === "project" || type === "product" ? "projects" : ""}`}>
                <p className={`stage-number`}>0{!!type && (type === "project" || type === "product") ? id + 1 : id}</p>
                <div className={`stage-title`}>{title}</div>
                <p className={`stage-description`}>{description}</p>
                {!!type && type === "project" ? (
                    <Link className={"project-link"} to={"/projects/" + destination + ""}>
                        {t("projects.button")}
                    </Link>
                ) : type === "product" ? (
                    <a
                        className={"project-link"}
                        href={"https://growcards.ru"}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t("products.button")}
                    </a>
                ) : null}
            </div>
            <div className={`stage-image ${type === "project" || type === "product" ? "projects" : ""}`}>
                <GatsbyImage alt="" image={imag} />
            </div>
        </div>
    );
};

export default Stage;

Stage.propTypes = {
    type: PropTypes.string,
    destination: PropTypes.string,
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    imag: PropTypes.shape({
        aspectRatio: PropTypes.number,
        base64: PropTypes.string,
        sizes: PropTypes.string,
        src: PropTypes.string,
        srcSet: PropTypes.string,
    }),
};
