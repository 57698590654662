import React from "react";
import Stage from "./stage";
import PropTypes, {arrayOf} from "prop-types";

const StageBuilder = ({type, data, image}) => {
    return (
        <>
            {data.map((post, index) => (
                <Stage
                    type={type}
                    key={`${type}-${post.id}`}
                    id={post.id}
                    title={post.title}
                    description={post.description}
                    destination={post.destination}
                    imag={image[index]}
                />
            ))}
        </>
    );
};

export default StageBuilder;

StageBuilder.propTypes = {
    type: PropTypes.string,
    data: arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            title: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            destination: PropTypes.string,
        }),
    ).isRequired,
    image: arrayOf(
        PropTypes.shape({
            aspectRatio: PropTypes.number,
            base64: PropTypes.string,
            sizes: PropTypes.string,
            src: PropTypes.string,
            srcSet: PropTypes.string,
        }),
    ).isRequired,
};
