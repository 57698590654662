import React from "react";
import {useStaticQuery, graphql} from "gatsby";
import StageBuilder from "./common/stageBuilder";
import {useTranslation} from "react-i18next";

const Stages = () => {
    const {t} = useTranslation();

    const textData = [];

    for (let i = 1; i < 4; i++) {
        textData.push({
            id: i,
            title: t(`stages.${i}.title`),
            description: t(`stages.${i}.description`),
        });
    }

    const data = useStaticQuery(graphql`
        query {
            stageImage1: file(relativePath: {eq: "index/stages/stage-1.png"}) {
                childImageSharp {
                    gatsbyImageData
                }
            }
            stageImage2: file(relativePath: {eq: "index/stages/stage-2.jpg"}) {
                childImageSharp {
                    gatsbyImageData
                }
            }
            stageImage3: file(relativePath: {eq: "index/stages/stage-3.png"}) {
                childImageSharp {
                    gatsbyImageData
                }
            }
        }
    `);

    let imagesData = [];

    for (let i = 1; i < 4; i++) {
        imagesData.push(data[`stageImage${i}`].childImageSharp.gatsbyImageData);
    }

    return (
        <div className="stages" id="stages">
            <div className="component-title">{t("stages.title")}</div>
            <div className="stages-wrapper">
                <div className="stages-bg">{null}</div>
                <div className="container">
                    <StageBuilder type={"stage"} data={textData} image={imagesData} />
                </div>
            </div>
        </div>
    );
};

export default Stages;
